import { EventBus } from '../bus.js';
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
    props: {
        categoriesicon: {
            type: Array,
            requred: false,
            default: () => { return []}
        }
    },
    data() {
        return{
            showSearchResult: false,
            query: '',
            timerId: ''
        }
    },
    created() {
        let searchForm = document.querySelector(".search-form")
        document.addEventListener('click', e => {
            let target = e.target;
            searchForm = document.querySelector(".search-form");
            if (!(!!searchForm && (searchForm.contains(target) || target.closest(".search-form") == searchForm))) {
                this.closePopupSearchResult();
            }
        })
    },
    computed: {
        ...mapGetters('filter', {
            getFilterValueByField: 'getFilterValueByField',
        }),
        ...mapGetters('products', {
            searchHints: 'getSearchHints',
        }),
        categoriesIconById() {
            if (this.categoriesicon.length) {
                let categoriesIconById = {};
                this.categoriesicon.forEach(element => {
                    categoriesIconById[element.id] = element.icon;
                });
                return categoriesIconById;
            }
            return false;
        },
    },
    methods: {
        ...mapMutations('filter', {
            setFilterValueByField: 'SET_FILTER_VALUE_BY_FIELD',
            setDefaultFilter: 'RESET_FILTER',
            setEmptyFilter: 'CLEAR_FILTER',
        }),
        ...mapMutations('products', {
            resetSearchHints: 'RESET_SEARCH_HINTS',
        }),
        ...mapActions('products', {
            getSearchHintsFromApi: 'getSearchHintsFromApi'
        }),
        ...mapActions('catalog', {
            getCategoryChildListByCategoryRootIdFromApi: 'getCategoryChildListByCategoryRootIdFromApi'
        }),
        applyFilter() {
            EventBus.$emit('searchByFilter');
            this.closePopupSearchResult();
        },
        openSearchPage() {
            window.location.href = '/products/?name=' + this.query;
        },
        openMobileFilter() {
            EventBus.$emit('openMobileFilter');
        },
        openPopupSearchResult() {
            this.showSearchResult = true;
        },
        closePopupSearchResult() {
            this.showSearchResult = false;
        },
        search(){
            this.setDefaultFilter();
            this.setFilterValueByField({
                field: 'name',
                value: this.query
            })
            this.applyFilter();
        },
        setFilters(searchHint) {
            this.setDefaultFilter();
            if (!!(searchHint.categoryChildId ?? searchHint.categoryChildIdCatalog)) this.getCategoryChildListByCategoryRootIdFromApi({categoryRootId: searchHint.categoryRootId ?? searchHint.categoryRootIdCatalog});
            this.setFilterValueByField({
                field: 'categoryRootId',
                value: searchHint.categoryRootId ?? searchHint.categoryRootIdCatalog
            })
            this.setFilterValueByField({
                field: 'categoryRootName',
                value: searchHint.categoryRootName ?? searchHint.categoryRootNameCatalog
            })
            this.setFilterValueByField({
                field: 'categoryChildId',
                value: searchHint.categoryChildId ?? searchHint.categoryChildIdCatalog
            })
            this.setFilterValueByField({
                field: 'categoryChildName',
                value: searchHint.categoryChildName ?? searchHint.categoryChildNameCatalog
            })
            this.setFilterValueByField({
                field: 'name',
                value: searchHint.article
            })
            this.query = '';
            this.applyFilter();
        },
        resetQuery() {
            this.query = '';
            this.applyFilter();
        }
    },
    watch: {
        query(val) {
            if (!!val && val.length > 2) {
                clearTimeout(this.timerId);
                this.timerId = setTimeout(() => {
                    this.getSearchHintsFromApi(val);
                }, 555);
            } else {
                this.resetSearchHints();
            }
        }
    }
}