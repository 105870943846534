function defaultFilter() { return {
    storeCountry: localStorage.getItem('storeCountry'),
    storeCity: localStorage.getItem('storeCity') ?? (localStorage.getItem('storeCountry') ? '' : null),
}}


const state = {
    productFilter: {},
    filter: defaultFilter(),
    applyFilter: {},
    chipsFilter: {},
    fieldsNotUsedFilter: [],
    fieldsChips: []
}


const mutations = {
    SET_FIELDS_CHIPS (state, fields) {
        state.fieldsChips = fields;
    },
    SET_FIELDS_NOT_USED_FILTER (state, fields) {
        state.fieldsNotUsedFilter = fields;
    },
    SET_FILTER_VALUE_BY_FIELD (state, payload) {
        Vue.set(state.filter, payload.field, payload.value);
    },
    SET_FILTER_VALUE (state, payload) {
        state.filter = Object.assign({}, payload);
    },
    SET_APPLY_FILTER_VALUE (state) {
        state.applyFilter = Object.assign({}, state.filter);
    },
    SET_CHIPS_FILTER_VALUE (state) {
        state.chipsFilter = {};
        state.fieldsChips.forEach(field => {
            if (state.applyFilter.hasOwnProperty(field)) {
                if (!!state.applyFilter[field]){
                    switch (field) {
                        case 'isUsed':
                            if (state.applyFilter[field] == 'false') {
                                Vue.set(state.chipsFilter, field, 'Новая');
                            } else if (state.applyFilter[field] == 'true') {
                                Vue.set(state.chipsFilter, field, 'Б/У');
                            }
                            break;
                        case 'isInstallationParts':
                            if (!!state.applyFilter[field]) {
                                Vue.set(state.chipsFilter, field, 'С установкой');
                            }
                            break;
                        default:
                            Vue.set(state.chipsFilter, field, state.applyFilter[field]);
                            break;
                    }
                } else {
                    delete state.chipsFilter[field]
                }
            }
        });
    },
    RESET_FILTER (state) {
        state.filter = defaultFilter();
    },
    CLEAR_FILTER (state) {
        state.filter = {};
    },
    DELETE_FILTER: (state, field) => {
        if (state.filter.hasOwnProperty(field)) {
            Vue.set(state.filter, field, '');
            if (field == 'categoryRootName') {
                Vue.set(state.filter, 'categoryRootId', '');
                Vue.set(state.filter, 'categoryChildId', '');
                Vue.set(state.filter, 'categoryChildName', '');
            }
            if (field == 'categoryChildName') {
                Vue.set(state.filter, 'categoryChildId', '');
                Vue.set(state.filter, 'categoryChildName', '');
            }
            if (field == 'brandName') {
                Vue.set(state.filter, 'brandId', '');
                Vue.set(state.filter, 'modelId', '');
                Vue.set(state.filter, 'modelName', '');
                Vue.set(state.filter, 'modificationId', '');
                Vue.set(state.filter, 'modificationName', '');
            }
            if (field == 'modelName') {
                Vue.set(state.filter, 'modelId', '');
                Vue.set(state.filter, 'modificationId', '');
                Vue.set(state.filter, 'modificationName', '');
            }
            if (field == 'modificationName') {
                Vue.set(state.filter, 'modificationId', '');
            }
            if (field == 'storeCountry') {
                Vue.set(state.filter, 'storeCity', '');
                localStorage.removeItem(field)
                localStorage.removeItem('storeCity')
            }
            if (field == 'storeCity') {
                localStorage.removeItem(field)
            }
        }
    }
}

const getters = {
    getFilterValueByField: state => field => {
        return state.filter[field] ?? '';
    },
    getFilterSearch: state => {
        return state.filter;
    },
    getAppliedFilterValueByField: state => field => {
        return state.applyFilter[field] ?? '';
    },
    getAppliedFilter: state => {
        return state.applyFilter;
    },
    getChipsFilter: state => {
        return state.chipsFilter;
    },
    isFiltered: state => {
        if ((Object.keys(state.applyFilter).length > 1) || (Object.keys(state.applyFilter).length == 1 && !Object.keys(state.applyFilter).includes('clientId'))) {
            for (const key in state.filter) {
                if (state.filter.hasOwnProperty(key) && key !='clientId' && key !='brandId' && (state.applyFilter[key] != '') && !state.fieldsNotUsedFilter.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    },
    hasFilter: state => {
        if ((Object.keys(state.filter).length > 1) || (Object.keys(state.filter).length == 1 && !Object.keys(state.filter).includes('clientId'))) {
            for (const key in state.filter) {
                if (state.filter.hasOwnProperty(key) && key !='clientId' && key !='brandId' && (state.filter[key] != '') && !state.fieldsNotUsedFilter.includes(key)) {
                    return true;
                }
            }
        }
        return false;
    },
    canSearch: state => {
	    console.log('test');
	return true;

        if (state.applyFilter.name || state.applyFilter.cross || (state.applyFilter.brandId && state.applyFilter.modelId) || (state.applyFilter.categoryRootId && state.applyFilter.categoryChildId)) {
            return true;
        }
        return false;
    },
    getFilterForRequest: state => {
        let filterSearch = [];
        for (const key in state.filter) {
            if (state.filter.hasOwnProperty(key) && key !='clientId' && !!state.filter[key] && !state.fieldsNotUsedFilter.includes(key)) {
                filterSearch.push( key + '=' + state.filter[key] )
            }
        }
        filterSearch = filterSearch.join('&');
        return filterSearch;
    },
    getFilterForAnalog: state => fieldsNotUsedFilter => {
        let filterSearch = [];
        for (const key in state.applyFilter) {
            if (state.filter.hasOwnProperty(key) && !!state.applyFilter[key] && !fieldsNotUsedFilter.includes(key)) {
                filterSearch.push( key + '=' + state.applyFilter[key] )
            }
        }
        filterSearch = filterSearch.join('&');
        if (!!filterSearch) filterSearch = '&' + filterSearch;
        return filterSearch;
    },
}

const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
