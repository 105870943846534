import Vue from 'vue';
import Vuex from 'vuex';

import auth from './store/auth';
import profile from './store/profile';
import filter from './store/filter';
import products from './store/products';
import catalog from './store/catalog';
import favorites from './store/favorites';
import errors from './store/errors';
import statusRequest from './store/status-request';
import order from './store/order';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        profile,
        filter,
        products,
        catalog,
        favorites,
        errors,
        statusRequest,
        order
    }
})