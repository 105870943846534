import { makeUrl, makeReqConfig } from '../service/api-config';

const state = {
    limitProducts: 20,
    offsetProducts: 0,
    products: [],
    searchHints: [],
    productsCategories: [],
    loadingProducts: false,
    loadingSearchHints: false,
    productInfo: {},
    showPagination: true,
    storeInfo: {},
    loadingProductsCategories: false,
    productsViews: {},
    productsComments: {},
    productsContacts: {},
};

const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = [...state.products, ...products];
    },
    SET_SEARCH_HINTS(state, products) {
        state.searchHints = products;
    },
    SET_PRODUCTS_CATEGORIES(state, data) {
        state.productsCategories = data;
    },
    SET_PRODUCT_INFO(state, data) {
        state.productInfo = data;
    },
    SET_STORE_INFO(state, data) {
        state.storeInfo = data;
    },
    SET_PAGINATION_LIMIT(state, limitProducts) {
        state.limitProducts = limitProducts;
    },
    SET_PAGINATION_OFFSET(state, offsetProducts) {
        state.offsetProducts = offsetProducts;
    },
    SET_LOADING_PRODUCTS(state, loadingProducts) {
        state.loadingProducts = loadingProducts;
    },
    SET_LOADING_SEARCH_HINTS(state, loadingSearchHints) {
        state.loadingSearchHints = loadingSearchHints;
    },
    RESET_PRODUCTS(state) {
        state.products = [];
    },
    RESET_SEARCH_HINTS(state) {
        state.searchHints = [];
    },
    RESET_PRODUCTS_CATEGORIES(state) {
        state.productsCategories = [];
    },
    RESET_PRODUCT_INFO(state) {
        state.productInfo = {};
    },
    RESET_STORE_INFO(state) {
        state.storeInfo = {};
    },
    SET_LOADING_PRODUCTS_CATEGORIES(state, data) {
        state.loadingProductsCategories = data;
    },
    SET_SHOW_PAGINATION(state, showPagination) {
        state.showPagination = showPagination;
    },
    SET_PRODUCT_VIEWS(state, { id, count }) {
        Vue.set(state.productsViews, id, count ?? null);
    },
    SET_PRODUCT_COMMENTS(state, { id, comments }) {
        Vue.set(state.productsComments, id, comments);
    },
    SET_PRODUCT_CONTACTS(state, { id, contacts }) {
        Vue.set(state.productsContacts, id, contacts);
    },
};

const getters = {
    getProducts: (state) => {
        return state.products;
    },
    getSearchHints: (state) => {
        return state.searchHints;
    },
    getProductsCategories: (state) => {
        return state.productsCategories;
    },
    getOffsetProducts: (state) => {
        return state.offsetProducts;
    },
    getLoadingProducts: (state) => {
        return state.loadingProducts;
    },
    getLoadingSearchHints: (state) => {
        return state.loadingSearchHints;
    },
    getProductInfo: (state) => {
        return state.productInfo;
    },
    getStoreInfo: (state) => {
        return state.storeInfo;
    },
    getLoadingProductsCategories: (state) => {
        return state.loadingProductsCategories;
    },
    getShowPagination: (state) => {
        return state.showPagination;
    },
    getProductViews: (state) => (id) => {
        return state.productsViews[id];
    },
    getProductComments: (state) => (id) => {
        return state.productsComments[id];
    },
    getProductContacts: (state) => (id) => {
        return state.productsContacts[id];
    },
};

const actions = {
    getProductsFromApi: (context) => {
        let request =
                '?offset=' +
                context.state.offsetProducts +
                '&limit=' +
                (context.state.limitProducts + 1),
            filterForRequest =
                context.rootGetters['filter/getFilterForRequest'],
            clientId =
                context.rootGetters['filter/getFilterValueByField']('clientId');
        if (filterForRequest) request += '&' + filterForRequest;
        if (!!clientId) request += '&clientId=' + clientId;
        let link = makeUrl(`/products` + request, undefined, false);
        context.commit('SET_LOADING_PRODUCTS', true);
        axios
            .get(link)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    if (
                        response.result.products.length <
                        context.state.limitProducts + 1
                    ) {
                        context.commit('SET_SHOW_PAGINATION', false);
                    } else {
                        response.result.products.pop();
                        context.commit('SET_SHOW_PAGINATION', true);
                    }
                    context.state.offsetProducts +=
                        response.result.products.length;
                    context.commit('SET_PRODUCTS', response.result.products);
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_PRODUCTS', false);
            });
    },
    getSearchHintsFromApi: (context, name) => {
        let request = '?name=' + name;
        let link = makeUrl(`/products-refining` + request);
        context.commit('SET_LOADING_SEARCH_HINTS', true);
        axios
            .get(link)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit(
                        'SET_SEARCH_HINTS',
                        response.result.products
                    );
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SEARCH_HINTS', false);
            });
    },
    getProductsCategoriesFromApi: (context) => {
        if (!state.loadingProductsCategories) {
            let request = context.rootGetters['filter/getFilterForRequest'],
                clientId =
                    context.rootGetters['filter/getFilterValueByField'](
                        'clientId'
                    );

            if (!!clientId) {
                if (!!request) request += '&';
                request += 'clientId=' + clientId;
            }
            if (request) request = '?' + request;
            let link = makeUrl(
                `/products-categories` + request,
                undefined,
                false
            );
            context.commit('SET_LOADING_PRODUCTS_CATEGORIES', true);
            axios
                .get(link)
                .then((r) => r.data)
                .then((response) => {
                    if (response.success) {
                        context.commit(
                            'SET_PRODUCTS_CATEGORIES',
                            response.result
                        );
                    }
                })
                .catch((errors) => {
                    context.commit('RESET_PRODUCTS_CATEGORIES');
                })
                .finally(() => {
                    context.commit('SET_LOADING_PRODUCTS_CATEGORIES', false);
                });
        } else {
            setTimeout(() => {
                context.commit('SET_LOADING_PRODUCTS_CATEGORIES', false);
            }, 200);
        }
    },
    getProductByIdFromApi: (context, id) => {
        let link = makeUrl(`/products/` + id, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        axios.get(link, config).then((response) => {
            if (
                !!response &&
                !!response.data &&
                !!response.data.success &&
                !!response.data.result
            ) {
                context.commit(
                    'SET_PRODUCT_INFO',
                    response.data.result.product
                );
                context.commit('SET_STORE_INFO', response.data.result.store);
            }
        });
    },
    getProductViews: (context, id) => {
        let link = makeUrl(
                '/statistics/products/' + id + '/show',
                undefined,
                false
            ),
            config = makeReqConfig(context.rootState.auth.token);
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_PRODUCT_VIEWS', {
                        id,
                        count: response.result.count,
                    });
                }
            })
            .catch((errors) => {});
    },
    getProductComments: (context, id) => {
        let link = makeUrl('/products/' + id + '/comments', undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_PRODUCT_COMMENTS', {
                        id,
                        comments: response.result,
                    });
                }
            })
            .catch((errors) => {});
    },
    getProductContacts: (context, id) => {
        let link = makeUrl('/products/' + id + '/contacts', undefined, false);
        axios
            .get(link)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_PRODUCT_CONTACTS', {
                        id,
                        contacts: response.result,
                    });
                }
            })
            .catch((errors) => {});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
