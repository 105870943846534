import { makeUrl, makeReqConfig } from '../service/api-config';
import { TIME_SHOW_REQUEST_RESULT } from '../service/const';

const state = {
    orderInfo: {},
};

const mutations = {
    SET_ORDER_INFO_BY_FIELD(state, payload) {
        Vue.set(state.orderInfo, payload.field, payload.val);
    },
    RESET_ORDER_INFO(state) {
        state.orderInfo = {};
    },
};

const getters = {
    getOrderInfoByField: (state) => (field) => {
        if (state.orderInfo[field]) return state.orderInfo[field];
        return '';
    },
};

const actions = {
    sendOrder: (context, payload) => {
        context.commit(
            'statusRequest/SET_PROCESS_UPDATE',
            { page: payload.page, value: true },
            { root: true }
        );
        let link = makeUrl(`/order`, undefined, false),
            config = makeReqConfig(context.rootState.auth.token),
            formData = new FormData();
        for (const key in context.state.orderInfo) {
            if (
                context.state.orderInfo.hasOwnProperty(key) &&
                key !== 'methods'
            ) {
                formData.append(key, context.state.orderInfo[key]);
            }
        }
        axios
            .post(link, formData, config)
            .then((response) => {
                if (response.data.success) {
                    context.commit(
                        'statusRequest/SET_RESULT_UPDATE',
                        { page: payload.page, value: 'success' },
                        { root: true }
                    );
                    context.commit('RESET_ORDER_INFO');
                }
            })
            .catch((errors) => {
                context.commit(
                    'statusRequest/SET_RESULT_UPDATE',
                    { page: payload.page, value: 'error' },
                    { root: true }
                );
                setTimeout(() => {
                    context.commit(
                        'statusRequest/SET_RESULT_UPDATE',
                        { page: payload.page, value: '' },
                        { root: true }
                    );
                }, TIME_SHOW_REQUEST_RESULT);
                if (
                    !!errors.response &&
                    !!errors.response.data &&
                    !!errors.response.data.errors
                )
                    context.commit(
                        'errors/SET_ERRORS',
                        {
                            page: payload.page,
                            errors: errors.response.data.errors,
                        },
                        { root: true }
                    );
            })
            .finally(() => {
                context.commit(
                    'statusRequest/SET_PROCESS_UPDATE',
                    { page: payload.page, value: false },
                    { root: true }
                );
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
