window.Vue = require('vue')

import store from './store-catalog.js'
import searchbar from './components/SearchBarAllPage.vue'
import searchbarmp from './components/SearchBarMainPage.vue'

// Axios
window.axios = require('axios')
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

let tokenCSRF = document.head.querySelector('meta[name="csrf-token"]')
if (tokenCSRF) window.axios.defaults.headers.common['X-CSRF-TOKEN'] = tokenCSRF.content


window.axios.interceptors.response.use(
  function (response) {
      return response;
  },
  function (error) {
      if (error && error.response){
          switch (error.response.status) {
              case 401:
                  store.dispatch('auth/logout');
                  break;
              case 423:
                store.dispatch('auth/logout');
                  break;
              default:
                  return Promise.reject(error);
          }
      } else {
          return Promise.reject(error);
      }
  }
);

// Трекер ошибок
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
let sentryOpt = {
    Vue,
    attachProps: true,
    logErrors: process.env.NODE_ENV === 'development'
}
Sentry.init({
    dsn: process.env.MIX_SENTRY_DSN_PUBLIC,
    integrations: [new Integrations.Vue(sentryOpt), new Integrations.ExtraErrorData() ],
    environment: process.env.NODE_ENV,
})

new Vue({
    el: '#searchBar',
    store,
    components: {
        searchbar,
        searchbarmp
    },
});