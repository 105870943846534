<template>
    <div class="search-form search-form_all-page">
        <div class="page-container search-form__container">
            <div class="search-form__inner">
                <div class="search-form__input search-input" :class="{'search-input_not-empty': !!query}">
                    <div class="search-input__inner">
                        <input v-model="query" @focus="openPopupSearchResult"  class="search-input__input" @keyup.enter="openSearchPage()" type="text" placeholder="№ детали или название" />
                        <span class="search-input__icon search-input__icon-clear icon-close" @click="resetQuery"></span>
                    </div>
                    <a :href="'/products/?name=' + query" class="search-input__button search-input__button-search"><span class="search-input__icon search-input__icon-search icon-search-2"></span></a>
                </div>
                <a :href="'/products/?name=' + query" class="button button-secondary search-form__button">Найти</a>
            </div>
            <transition name="fade">
                <div v-if="showSearchResult && !!searchHints && !!searchHints.length" class="search-form__result_fake"></div>
            </transition>
            <transition name="scaleY">
                <div v-if="showSearchResult && !!searchHints && !!searchHints.length" class="search-form__result">
                    <div class="products-categories">
                    <a :href="'/products/?name=' + searchHint.article + '&categoryRootId=' + searchHint.categoryRootId + '&categoryChildId='  + searchHint.categoryChildId" v-for="(searchHint, id) in searchHints" :key="id" class="products-categories__item">    
                        <span class="products-categories__item-supplier">{{searchHint.supplierName}}</span>
                        <span class="products-categories__item-article">{{searchHint.article}}</span>
                        <span v-if="!!categoriesIconById[searchHint.categoryRootId]" :class="'products-categories__item-icon icon-' + categoriesIconById[searchHint.categoryRootId]"></span>
                        <span v-else :class="'products-categories__item-icon icon-' + categoriesIconById[1]"></span>
                        <span class="products-categories__item-value">
                            <span v-if="searchHint.categoryRootName" class="products-categories__item-root">{{searchHint.categoryRootName}}</span>
                            <span v-else class="products-categories__item-root">{{searchHint.categoryRootNameCatalog}}</span>
                        <span class="products-category__icon-delimiter icon-arrow-right-3"></span>
                            <span v-if="searchHint.categoryChildName" class="products-categories__item-child">{{searchHint.categoryChildName}}</span>
                            <span v-else class="products-categories__item-child">{{searchHint.categoryChildNameCatalog}}</span>
                        </span>
                    </a>
                    </div>
                    <div class="search-form__result-more">
                        <a :href="'/products/?name=' + query" class="search-form__btn-more">К результатам поиска<span class="search-form__btn-more-icon icon-arrow-right-2"></span></a>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import searchBar from '../mixins/search-bar'
export default {
    mixins: [searchBar],
}
</script>