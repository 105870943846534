export const // Генерация адреса
    PREFIX = 'api', // Префикс для всех запросов АПИ
    VERSION = '1', // Номер версии
    HOSTNAME = '', // Базовый адрес сервера
    HOSTNAME_CATALOG = '',
    // Генерация правильного URL адрес API с учетом версии и имени хоста
    makeUrl = function (resource, version, isMicroservice = true) {
        if (!version) {
            version = VERSION;
        }

        return (
            HOSTNAME +
            '/' +
            PREFIX +
            (isMicroservice ? '/microservices' : '') +
            '/v' +
            version +
            resource
        );
    },
    makeUrlCatalog = function (resource, version, isMicroservice = true) {
        if (!version) {
            version = VERSION;
        }
        return (
            HOSTNAME_CATALOG +
            '/' +
            PREFIX +
            (isMicroservice ? '/microservices' : '') +
            '/v' +
            version +
            resource
        );
    },
    makeReqConfig = function (
        token = '',
        tokenType = 'Bearer',
        contentType = 'application/json'
    ) {
        let config = {
            headers: {
                'Content-Type': contentType,
                Accept: 'application/json',
            },
        };
        if (!!token) {
            config.headers['Authorization'] = [tokenType, token].join(' ');
        }
        return config;
    },
    makeMultipartReqConfig = function (token = '', tokenType = 'Bearer') {
        return makeReqConfig(token, tokenType, 'multipart/form-data');
    };
