import {
    makeUrl,
    makeReqConfig,
    makeMultipartReqConfig,
} from '../service/api-config';
import { TIME_SHOW_REQUEST_RESULT } from '../service/const';

const state = {
    user: JSON.parse(localStorage.getItem('clientInfo')) || {},
    changeableUserInfo: JSON.parse(localStorage.getItem('clientInfo')) || {},
    profileFields: ['clientId', 'name', 'email', 'role', 'subrole'],
    dialogBanShow: false,
    banReason: '',
    sendToConfirm: false,
};

const mutations = {
    SET_USER: (state, data) => {
        localStorage.setItem('clientInfo', JSON.stringify(data));
        state.user = data;
        state.changeableUserInfo = Object.assign({}, data);
    },
    SET_USER_INFO_BY_FIELD: (state, payload) => {
        Vue.set(state.changeableUserInfo, payload.field, payload.value);
    },
    SET_COUNT_PRODUCTS: (state, action) => {
        if (action === parseInt(action, 10)) {
            Vue.set(
                state.user,
                'countProducts',
                state.user.countProducts - action
            );
        } else if (action == 'add') {
            Vue.set(state.user, 'countProducts', state.user.countProducts + 1);
        } else {
            Vue.set(state.user, 'countProducts', state.user.countProducts - 1);
        }
    },
    DELETE_NOT_SAVE_INFO: (state) => {
        state.changeableUserInfo = Object.assign({}, state.user);
    },
    SET_DIALOG_BAN_SHOW: (state, data) => {
        state.dialogBanShow = data.dialogBanShow;
        state.banReason = data.banReason;
    },
    SET_SEND_TO_CONFIRM(state, payload) {
        state.sendToConfirm = payload;
    },
};

const getters = {
    getUserInfo: (state) => {
        return state.user;
    },
    getUserInfoByField: (state) => (field) => {
        return state.user[field];
    },
    getChangeableUserInfo: (state) => {
        return state.changeableUserInfo;
    },
    getDialogBanShow: (state) => {
        return state.dialogBanShow;
    },
    getBanReason: (state) => {
        return state.banReason;
    },
    getSendToConfirm: (state) => {
        return state.sendToConfirm;
    },
    getChangeEmail: (state) => {
        let getChangeEmail = false;
        if (state.changeableUserInfo['email'] !== state.user['email']) {
            getChangeEmail = true;
        }
        return getChangeEmail;
    },
    getChangeClientId: (state) => {
        let getChangeClientId = false;
        if (state.changeableUserInfo['clientId'] !== state.user['clientId']) {
            getChangeClientId = true;
        }
        return getChangeClientId;
    },
};

const actions = {
    clientInfoShow: (context) => {
        let link = makeUrl(`/profile/`, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_USER', response.result);
                }
            });
    },
    updateProfileInfo: (context, payload) => {
        context.commit(
            'statusRequest/SET_PROCESS_UPDATE',
            { page: payload.page, value: true },
            { root: true }
        );
        let link = makeUrl(`/profile/update/`, undefined, false),
            config = makeReqConfig(context.rootState.auth.token),
            formData = new FormData();
        context.state.profileFields.forEach((key) => {
            if (context.state.changeableUserInfo.hasOwnProperty(key)) {
                formData.append(key, context.state.changeableUserInfo[key]);
            }
        });

        axios
            .post(link, formData, config)
            .then((response) => {
                if (response.data.success) {
                    if (
                        context.state.changeableUserInfo.role !==
                        context.state.user.role
                    ) {
                        let userMenuBtnName = document.querySelector(
                            '.user-menu-btn__name'
                        );
                        if (!!userMenuBtnName) {
                            if (
                                context.state.changeableUserInfo.role == 'buyer'
                            ) {
                                userMenuBtnName.innerText =
                                    context.state.changeableUserInfo.name;
                            } else if (
                                context.state.changeableUserInfo.role ==
                                    'seller' &&
                                !!context.state.changeableUserInfo
                                    .organizationData &&
                                !!context.state.changeableUserInfo
                                    .organizationData.name
                            ) {
                                userMenuBtnName.innerText =
                                    context.state.changeableUserInfo.organizationData.name;
                            }
                        }
                        let userMenu = document.getElementById('userMenu');
                        if (!!userMenu) {
                            function deleteUserMenuSellerElement() {
                                let userMenuSellerElement =
                                    userMenu.querySelectorAll(
                                        "[data-role='seller']"
                                    );
                                userMenuSellerElement.forEach((element) => {
                                    element.parentNode.removeChild(element);
                                });
                            }
                            if (
                                context.state.changeableUserInfo.role ==
                                'seller'
                            ) {
                                deleteUserMenuSellerElement();
                                userMenu.innerHTML =
                                    `
                            <li><a href="/dashboard/products/add" class="user-menu__item"><span class="icon-plus-3"></span>Добавить объявление</a></li>
                            <li><span class="user-menu__item user-menu__item-group"><span class="icon-line"></span>Мои объявления</span>
                                <ul class="user-menu__submenu user-menu__submenu_active">
                                    <li><a href="/dashboard/products?isUsed=false" class="user-menu__item">Новые</a></li>
                                    <li><a href="/dashboard/products?isUsed=true" class="user-menu__item">Б/У</a></li>
                                </ul>
                            </li>` + userMenu.innerHTML;
                            } else if (
                                context.state.changeableUserInfo.role == 'buyer'
                            ) {
                                deleteUserMenuSellerElement();
                            }
                        }
                    }
                    context.commit(
                        'SET_USER',
                        context.state.changeableUserInfo
                    );
                    if (
                        !!response.data.result &&
                        !!response.data.result.sendToConfirm
                    ) {
                        context.commit(
                            'SET_SEND_TO_CONFIRM',
                            response.data.result.sendToConfirm
                        );
                        context.commit('SET_USER_INFO_BY_FIELD', {
                            field: 'isEmailVerify',
                            value: false,
                        });
                    } else {
                        context.commit('SET_SEND_TO_CONFIRM', false);
                    }
                    context.commit(
                        'statusRequest/SET_RESULT_UPDATE',
                        { page: payload.page, value: 'success' },
                        { root: true }
                    );
                }
            })
            .catch((errors) => {
                context.commit(
                    'statusRequest/SET_RESULT_UPDATE',
                    { page: payload.page, value: 'error' },
                    { root: true }
                );
                if (
                    !!errors.response &&
                    !!errors.response.data &&
                    !!errors.response.data.errors
                )
                    context.commit(
                        'errors/SET_ERRORS',
                        {
                            page: payload.page,
                            errors: errors.response.data.errors,
                        },
                        { root: true }
                    );
            })
            .finally(() => {
                setTimeout(() => {
                    context.commit(
                        'statusRequest/SET_RESULT_UPDATE',
                        { page: payload.page, value: '' },
                        { root: true }
                    );
                }, TIME_SHOW_REQUEST_RESULT);
                context.commit(
                    'statusRequest/SET_PROCESS_UPDATE',
                    { page: payload.page, value: false },
                    { root: true }
                );
            });
    },
    updateProfileAvatar: (context, avatar) => {
        if (!!avatar) {
            let link = makeUrl(`/profile/update-avatar/`, undefined, false),
                config = makeMultipartReqConfig(context.rootState.auth.token),
                formData = new FormData();
            formData.append('avatar', avatar);
            return axios.post(link, formData, config).then((response) => {
                return response;
            });
        }
    },
    deleteProfileAvatar: (context, payload) => {
        let link = makeUrl(`/profile/delete-avatar/`, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        axios
            .delete(link, config)
            .then(
                (response) => {
                    if (response.data.success) {
                        state.changeableUserInfo.avatar = '';
                        localStorage.setItem(
                            'clientInfo',
                            JSON.stringify(context.state.changeableUserInfo)
                        );
                        context.state.user = Object.assign(
                            {},
                            context.state.changeableUserInfo
                        );
                    }
                },
                (errors) => {
                    context.commit(
                        'errors/SET_ERRORS',
                        {
                            page: payload.page,
                            errors: errors.response.data.errors,
                        },
                        { root: true }
                    );
                }
            )
            .catch((errors) => {});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
