import { makeUrlCatalog, makeReqConfig } from '../service/api-config';

const state = {
    modelsByBrandList: {},
    enginesByModelList: {},
    loadingSelectList: {},
    suppliersList: [],
    categoryRootList: [],
    categoryChildList: {},
    catalogToken: tokenTecDoc,
};

const mutations = {
    SET_SUPPLIERS(state, data) {
        state.suppliersList = data;
    },
    SET_MODELS_BY_BRAND(state, payload) {
        Vue.set(state.modelsByBrandList, payload.brandId, payload.models);
    },
    SET_ENGINES_BY_MODEL(state, payload) {
        Vue.set(state.enginesByModelList, payload.modelId, payload.engines);
    },
    SET_LOADING_SELECT_LIST(state, payload) {
        Vue.set(state.loadingSelectList, payload.listName, payload.loading);
    },
    SET_CATEGORY_ROOT(state, categoryRootList) {
        let notSorted;
        categoryRootList = categoryRootList.filter((element) => {
            if (element.name.toLowerCase() == 'не отсортировано')
                notSorted = element;
            return element.name.toLowerCase() !== 'не отсортировано';
        });
        if (!!notSorted) categoryRootList.push(notSorted);
        notSorted = null;
        state.categoryRootList = categoryRootList;
    },
    SET_CATEGORY_CHILD_BY_CATEGORY_ROOT_ID(state, payload) {
        Vue.set(
            state.categoryChildList,
            payload.categoryRootId,
            payload.categoryChildList
        );
    },
};

const getters = {
    getSuppliersList: (state) => {
        return state.suppliersList;
    },

    getModelsByBrand: (state) => (brandId) => {
        if (!!state.modelsByBrandList[brandId])
            return state.modelsByBrandList[brandId];
        return [];
    },

    getEnginesByModel: (state) => (modelId) => {
        if (!!state.enginesByModelList[modelId])
            return state.enginesByModelList[modelId];
        return [];
    },

    getLoadingSelectList: (state) => (listName) => {
        if (state.loadingSelectList[listName])
            return state.loadingSelectList[listName];
        return false;
    },

    getCategoryRootList: (state) => {
        return state.categoryRootList;
    },

    getCategoryChildByCategoryRootId: (state) => (categoryRootId) => {
        if (!!state.categoryChildList[categoryRootId])
            return state.categoryChildList[categoryRootId];
        return [];
    },
};

const actions = {
    getSupplierListFromApi: (context) => {
        let link = makeUrlCatalog(`/supplier`, undefined, true),
            config = makeReqConfig(context.state.catalogToken);
        context.commit('SET_LOADING_SELECT_LIST', {
            listName: 'suppliersList',
            loading: true,
        });
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_SUPPLIERS', response.rows);
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SELECT_LIST', {
                    listName: 'suppliersList',
                    loading: false,
                });
            });
    },
    getModelsByBrandListFromApi: (context, data) => {
        let link = makeUrlCatalog(
                `/brand/` + data.brandId + `/models`,
                undefined,
                true
            ),
            config = makeReqConfig(context.state.catalogToken);

        context.commit('SET_LOADING_SELECT_LIST', {
            listName: 'modelsList',
            loading: true,
        });

        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_MODELS_BY_BRAND', {
                        brandId: data.brandId,
                        models: response.rows,
                    });
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SELECT_LIST', {
                    listName: 'modelsList',
                    loading: false,
                });
            });
    },
    getEngineByModelListFromApi: (context, data) => {
        let link = makeUrlCatalog(
                `/model/` + data.modelId + `/engine`,
                undefined,
                true
            ),
            config = makeReqConfig(context.state.catalogToken);
        context.commit('SET_LOADING_SELECT_LIST', {
            listName: 'modsList',
            loading: false,
        });
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_ENGINES_BY_MODEL', {
                        modelId: data.modelId,
                        engines: response.rows,
                    });
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SELECT_LIST', {
                    listName: 'modsList',
                    loading: false,
                });
            });
    },
    getCategoryRootListFromApi: (context) => {
        let link = makeUrlCatalog('/catalog', undefined, true),
            config = makeReqConfig(context.state.catalogToken);
        context.commit('SET_LOADING_SELECT_LIST', {
            listName: 'categoryRootList',
            loading: true,
        });
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_CATEGORY_ROOT', response.rows);
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SELECT_LIST', {
                    listName: 'categoryRootList',
                    loading: false,
                });
            });
    },
    getCategoryChildListByCategoryRootIdFromApi: (context, data) => {
        let link = makeUrlCatalog(
                '/catalog/' + data.categoryRootId,
                undefined,
                true
            ),
            config = makeReqConfig(context.state.catalogToken);
        context.commit('SET_LOADING_SELECT_LIST', {
            listName: 'categoryChildList',
            loading: true,
        });
        axios
            .get(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    context.commit('SET_CATEGORY_CHILD_BY_CATEGORY_ROOT_ID', {
                        categoryRootId: data.categoryRootId,
                        categoryChildList: response.rows,
                    });
                } else {
                }
            })
            .catch((errors) => {})
            .finally(() => {
                context.commit('SET_LOADING_SELECT_LIST', {
                    listName: 'categoryChildList',
                    loading: false,
                });
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
