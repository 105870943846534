const state = {
    token: localStorage.getItem('clientToken') || false,
    snackbarTotalError: {}
}


const mutations = {
    SET_SNACKBAR_TOTAL_ERROR: (state, data) => {
        state.snackbarTotalError = data;
    },
    LOGOUT: (state) => {
        localStorage.removeItem('clientInfo');
        localStorage.removeItem('clientToken');
        state.token = '';
    }
}


const getters = {
    isAuth: state => {
        return !!state.token
    },
    getSnackbarTotalErrorText: state => {
        if (!!state.snackbarTotalError && !!state.snackbarTotalError.text) return state.snackbarTotalError.text;
        return '';
    },
    getSnackbarTotalErrorStatus: state => {
        if (!!state.snackbarTotalError && !!state.snackbarTotalError.status) return state.snackbarTotalError.status;
        return false;
    },
}

const actions = {
    logout: (context) => {
        context.commit('LOGOUT');
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}