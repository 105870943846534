const state = {
    errors: {}
}


const mutations = {
    SET_ERROR_BY_FIELD ( state, payload ) {
        if (!state.errors[payload.page]) Vue.set(state.errors, payload.page, {});
        Vue.set(state.errors[payload.page], payload.field, payload.value);
    },
    SET_ERRORS ( state, payload ) {
        Vue.set(state.errors, payload.page, payload.errors)
    },
}


const getters = {
    getErrorByField:  state => data => {
        if (!!state.errors[data.page] && !!state.errors[data.page][data.field]) return state.errors[data.page][data.field];
        return "";
    },
    getErrorByPage:  state => page => {
        if (!!state.errors[page]) return state.errors[page];
        return "";
    },
    getOtherErrorByPage:  state => payload => {
        if (!!state.errors[payload.page]) {
            let errorsPage = Object.assign({}, state.errors[payload.page]),
                regx,
                errorsPageKeys;
            if (!!payload.formFields.length) {
                payload.formFields.forEach(fieldName => {
                    delete errorsPage[fieldName];
                });
            }
            if (!!payload.formArrayFields && !!payload.formArrayFields.length) {
                payload.formArrayFields.forEach(formFields => {
                    formFields.fields.forEach(field => {
                        regx = new RegExp('/^' + formFields.name + '.\d*.' + field + '$/g');
                        errorsPageKeys = Object.keys(state.errors[payload.page]);
                        errorsPageKeys.forEach(fieldName => {
                            if (!regx.test(fieldName)) delete errorsPage[fieldName];
                        });
                    });
                    
                });
            }
            return errorsPage;
        }
        return {};
    },
    hasErrors: state => payload => {
        for (const key in state.errors[payload.page]) {
            if (state.errors[payload.page].hasOwnProperty(key)) {
                if (!!state.errors[payload.page][key]) return true;
            }
        }
        return false;
    }
}


const actions = {
    resetOtherErrorByPage:  (context, payload) => {
        if (!!state.errors[payload.page]) {
            let errorsPageKeys = Object.keys(state.errors[payload.page]),
                regx;
            if (!!payload.formFields) {
                errorsPageKeys.forEach(fieldName => {
                    if (!payload.formFields.includes(fieldName)) {
                        if (!!payload.formArrayFields && !!payload.formArrayFields.length) {
                            payload.formArrayFields.forEach(formFields => {
                                formFields.fields.forEach(field => {
                                    regx = new RegExp('/^' + formFields + '.\d*.' + field + '$/g');
                                    if (regx.test(fieldName)) {
                                        context.commit('SET_ERROR_BY_FIELD', {
                                            page: payload.page,
                                            field: fieldName,
                                            value: ''
                                        })
                                    }
                                });
                                
                            });
                        } else {
                            context.commit('SET_ERROR_BY_FIELD', {
                                page: payload.page,
                                field: fieldName,
                                value: ''
                            })
                        }
                    }
                });
            }
        }
        return "";
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}