export const MAX_PASSWORD_LENGTH = 14,
    MAX_INN_LENGTH = 12,
    MAX_OGRN_LENGTH = 13,
    MAX_INPUT_DEFAULT_LENGTH = 255,
    MAX_TEXTAREA_DEFAULT_LENGTH = 1024,
    TIME_SHOW_REQUEST_RESULT = 7000,
    LOAD_STATUS_ITEMS = [
        { value: 'pending', text: 'Файл ожидает обработки' },
        { value: 'process', text: 'Файл в процессе обработки' },
        {
            value: 'completedSuccess',
            text: 'Обработка файла завершена успешно',
        },
        {
            value: 'completedError',
            text: 'Обработка файла завершена с ошибками',
        },
        { value: 'error', text: 'Ошибка обработки файла' },
    ],
    DELIMITER_ITEMS = [
        { text: 'Символ ;', value: ';' },
        { text: 'Символ ,', value: ',' },
        { text: 'Пробел', value: 's' },
        { text: 'Табуляция', value: 't' },
    ],
    METHOD_SUPPORT_LIST = [
        { text: 'Изменить телефон', value: 'сhangePhone' },
        { text: 'Проверка профиля', value: 'profileCheck' },
        { text: 'Другое', value: 'other' },
    ],
    ENCODING_ITEMS = [
        { text: 'utf8', value: 'UTF8' },
        { text: 'windows-1251', value: 'CP1251' },
    ],
    ORGANIZATION_TYPES = [
        // { text: 'Физ. лицо', value: 'organizationPhyz' },
        { text: 'ИП', value: 'organizationIp' },
        { text: 'Юр. лицо', value: 'organizationOoo' },
    ],
    PRODUCT_STATUS_ITEMS = [
        { text: 'Новая', value: 'false' },
        { text: 'Б/У', value: 'true' },
    ],
    STOCK_STATUS_ITEMS = [
        { text: 'В наличии', value: 'inStock' },
        { text: 'Под заказ', value: 'onOrder' },
    ],
    USER_ROLE_TYPES = [
        { text: 'Продавец', value: 'seller' },
        { text: 'Покупатель', value: 'buyer' },
    ],
    METHODS_LOAD_PRICE = [
        {
            text: 'Доб.',
            fulltext: 'Добавление',
            value: 'add',
        },
        {
            text: 'Зам.',
            fulltext: 'Замена',
            value: 'replace',
        },
    ],
    DELIVERY_COMPANY = [
        { name: 'Самовывоз' },
        { name: 'Деловые линии' },
        { name: 'Boxberry' },
        { name: 'ТК ПЭК' },
        { name: 'DHL' },
        { name: 'DPD' },
        { name: 'СПСР-Экспресс' },
        { name: 'Байкал Сервис' },
        { name: 'ЖелДорЭкспедиция' },
        { name: 'Энергия' },
        { name: 'КИТ' },
        { name: 'IML' },
        { name: 'Яндекс.Такси' },
    ],
    DELIVERY_TIME = [
        {
            id: '1',
            name: 'В наличии',
        },
        {
            id: '4',
            name: 'До 3 дней',
        },
        {
            id: '8',
            name: 'До недели',
        },
        {
            id: '15',
            name: 'До 2х недель',
        },
        {
            id: '30',
            name: 'До месяца',
        },
        {
            id: '31',
            name: 'Дольше месяца',
        },
    ],
    COUNTRY = [
        { country: 'Россия' },
        { country: 'Белорусь' },
        { country: 'Казахстан' },
        { country: 'Киргизия' },
    ],
    CITY_FILES = {
        Россия: 'ru_data',
        Белорусь: 'by_data',
        Казахстан: 'kz_data',
        Киргизия: 'kg_data',
    },
    GC_ICONS = {
        gc_delete: 'icon-delete',
        gc_attach: 'icon-attach',
        gc_visibility: 'icon-visibility',
        gc_breadcrumb: 'icon-arrow-left-2',
        gc_clock: 'icon-clock-2',
        gc_loader: 'icon-circle-loader',
        gc_alert: 'icon-alert-info',
        gc_search: 'icon-search',
        gc_search_2: 'icon-search-2',
        gc_cancel: 'icon-cancel',
        gc_add: 'icon-Plus-2',
        gc_add_2: 'icon-plus-3',
        gc_cabonet: 'icon-cabonet',
        gc_account: 'icon-account',
        gc_shop: 'icon-shop',
        gc_car: 'icon-car',
        gc_quest: 'icon-quest',
        gc_e_money: 'icon-e-money',
        gc_progress_download: 'icon-progress-download',
        md_visibility: 'icon-md-visibility',
        md_visibility_off: 'icon-md-visibility-off',
        md_done: 'icon-md-done',
        md_done_all: 'icon-md-done-all',
        md_create: 'icon-md-create',
        md_keyboard_backspace: 'icon-md-keyboard-backspace',
        dropdown: 'icon-Arrow-Drop-Down',
        menu: 'icon-md-menu',
        checkboxOn: 'icon-md-check-box',
        checkboxOff: 'icon-md-check-box-outline',
        expand: 'icon-Arrow-Drop-Down',
        sort: 'icon-Arrow-Drop-Down',
        prev: 'icon-chevron-left',
        next: 'icon-chevron-right',
    };
