
const state = {
    resultUpdate: {},
    processUpdate: {}
}


const mutations = {
    SET_RESULT_UPDATE ( state, payload ) {
        Vue.set(state.resultUpdate, payload.page, payload.value)
    },
    SET_PROCESS_UPDATE ( state, payload ) {
        Vue.set(state.processUpdate, payload.page, payload.value)
    },
}


const getters = {
    getResultUpdate: state  => page => {
        if (!!state.resultUpdate[page]) return state.resultUpdate[page];
        return '';
    },
    getProcessUpdate: state  => page => {
        if (!!state.processUpdate[page]) return state.processUpdate[page];
        return false;
    },
}


const actions = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}