import { makeUrl, makeReqConfig } from '../service/api-config';

const state = {
    favoritesList: [],
};

const mutations = {
    SET_FAVORITES_LIST(state, payload) {
        state.favoritesList = payload;
    },
};

const getters = {
    isFavorite: (state) => (id) => {
        if (!!state.favoritesList) {
            if (
                !!state.favoritesList.find((product) => {
                    return product.id == id;
                })
            )
                return true;
        }
        return false;
    },
    getFavoritesList: (state) => {
        return state.favoritesList;
    },
};

const actions = {
    addToFavorites(context, id) {
        let link = makeUrl(`/favorites/` + id, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        axios
            .post(link, '', config)
            .then((r) => r.data)
            .then((response) => {})
            .catch((errors) => {})
            .finally(() => {
                context.dispatch('getFavoritesFromApi');
            });
    },
    removeFromFavorites(context, payload) {
        let link = makeUrl(`/favorites/` + payload.id, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);

        axios
            .delete(link, config)
            .then((r) => r.data)
            .then((response) => {
                if (response.success) {
                    if (!!payload.tableName) {
                        if (context.state.favoritesList.length == 1) {
                            let tableOptions = context.rootGetters[
                                'tables/getTableOptions'
                            ](payload.tableName);
                            context.commit(
                                'tables/SET_TABLE_OPTIONS',
                                {
                                    tableName: payload.tableName,
                                    value: {
                                        page: tableOptions.page - 1 || 1,
                                    },
                                },
                                { root: true }
                            );
                        }
                    }
                }
            })
            .catch((errors) => {})
            .finally(() => {
                if (!!payload.tableName) {
                    context.dispatch('getFavoritesForTableFromApi', {
                        tableName: payload.tableName,
                    });
                } else {
                    context.dispatch('getFavoritesFromApi');
                }
            });
    },
    removeAllFromFavorites(context) {},
    getFavoritesFromApi(context) {
        let link = makeUrl(`/favorites`, undefined, false),
            config = makeReqConfig(context.rootState.auth.token);
        if (context.rootState.auth.token) {
            axios
                .get(link, config)
                .then((r) => r.data)
                .then((response) => {
                    if (response.success) {
                        context.commit(
                            'SET_FAVORITES_LIST',
                            response.result.products
                        );
                    } else {
                    }
                })
                .catch((errors) => {});
        } else {
            context.commit('SET_FAVORITES_LIST', []);
        }
    },
    getFavoritesForTableFromApi(context, data) {
        if (!context.rootGetters['tables/getLoadingTable'](data.tableName)) {
            context.commit(
                'tables/RESET_LOADING_ERRORS',
                {
                    tableName: data.tableName,
                },
                { root: true }
            );
            context.commit(
                'tables/SET_LOADING_TABLE',
                { tableName: data.tableName, value: true },
                { root: true }
            );
            let pagination = context.rootGetters['tables/getPagination'](
                    data.tableName
                ),
                sort = context.rootGetters['tables/getSort'](data.tableName),
                filterSearch = context.rootGetters['tables/getFilter'](
                    data.tableName
                ),
                link = makeUrl(`/favorites`, undefined, false),
                config = makeReqConfig(context.rootState.auth.token);
            if (!!sort) sort = '&' + sort;
            if (!!filterSearch) filterSearch = '&' + filterSearch;
            axios
                .get(link + '?' + pagination + sort + filterSearch, config)
                .then((r) => r.data)
                .then((response) => {
                    if (response.success) {
                        context.commit(
                            'tables/SET_TABLE_OPTIONS',
                            {
                                tableName: data.tableName,
                                value: {
                                    totalItems: response.result.count,
                                },
                            },
                            { root: true }
                        );
                        context.commit(
                            'SET_FAVORITES_LIST',
                            response.result.products
                        );
                    } else {
                    }
                })
                .catch((errors) => {
                    context.commit('SET_FAVORITES_LIST', []);
                    context.commit(
                        'tables/SET_TABLE_OPTIONS',
                        {
                            tableName: data.tableName,
                            value: {
                                totalItems: 0,
                            },
                        },
                        { root: true }
                    );
                    context.commit(
                        'tables/SET_LOADING_ERRORS',
                        {
                            tableName: data.tableName,
                            value: errors.response.data.errors,
                        },
                        { root: true }
                    );
                })
                .finally(() => {
                    context.commit(
                        'tables/SET_LOADING_TABLE',
                        { tableName: data.tableName, value: false },
                        { root: true }
                    );
                });
        } else {
            setTimeout(() => {
                context.commit(
                    'tables/SET_LOADING_TABLE',
                    { tableName: data.tableName, value: false },
                    { root: true }
                );
            }, 200);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
